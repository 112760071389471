import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { setDoc, doc, getDoc } from 'firebase/firestore'; 
import { Link } from 'react-router-dom';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrorMessage(''); 
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      await setDoc(doc(db, 'users', user.uid), {
        isPremium: false, 
        subscriptionDate: null 
      });

      navigate('/'); 
    } catch (error) {
      setErrorMessage("Registration error: " + error.message);
    }
  };

  const handleGoogleRegister = async () => {
    setErrorMessage('');
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      
      const userDocRef = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDocRef);
      
      if (!userSnapshot.exists()) {
        await setDoc(userDocRef, {
          isPremium: false,
          subscriptionDate: null
        });
      }

      navigate('/'); 
    } catch (error) {
      setErrorMessage("Google registration error: " + error.message);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#121212', 
    },
    formContainer: {
      backgroundColor: '#1e1e1e', 
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
      width: '300px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      padding: '10px',
      margin: '10px 0',
      borderRadius: '4px',
      border: '1px solid #444', 
      backgroundColor: '#292929', 
      color: '#ffffff', 
      boxSizing: 'border-box',
    },
    button: {
      padding: '10px 15px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
      width: '100%',
      margin: '10px 0', 
    },
    googleButton: {
      backgroundColor: '#db4437', 
      margin: '10px 0', 
    },
    error: {
      color: '#f44336', 
      margin: '10px 0',
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={{ color: '#ffffff' }}>Register</h2>
        {errorMessage && <div style={styles.error}>{errorMessage}</div>}
        <form style={styles.form} onSubmit={handleRegister}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={styles.input}
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            style={styles.input}
            required
          />
          <button type="submit" style={styles.button}>Register</button>
          <button type="button" onClick={handleGoogleRegister} style={{ ...styles.button, ...styles.googleButton }}>
            Register with Google
          </button>
        </form>
        <p style={{ color: '#ffffff' }}>Already have an account? <Link to="/login" style={{ color: '#007bff' }}>Sign in here</Link></p>
      </div>
    </div>
  );
};

export default Register;
