// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import SearchBar from './SearchBar';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const Header = ({ onSearch }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      // Error handling silently
    }
  };

  const handleSearchSubmit = async (searchTerm) => {
    try {
      if (!searchTerm?.trim()) return;

      const result = await onSearch(searchTerm);
      if (result) {
        navigate(`/search/${encodeURIComponent(searchTerm)}`);
      }
    } catch (error) {
      console.error('Error in the search:', error);
    }
  };

  const handleRedirectToInterfaz = () => {
    navigate('/interfaz');
  };

  const handleRedirectToPortfolio = () => {
    navigate('/portfolio'); 
  };

  const handleRedirectToScreener = () => {
    navigate('/screener'); 
  };

  return (
    <header style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#1e1e1e',
      color: '#ffffff',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
    }}>
      <h1 style={{ margin: 0, minWidth: '200px' }}>Financial Data</h1>
      
      <div style={{ 
        flex: 1, 
        display: 'flex', 
        justifyContent: 'center',
        paddingLeft: user ? '100px' : '0'
      }}>
        <SearchBar onSubmit={onSearch} />
      </div>
      
      <nav style={{ 
        display: 'flex', 
        alignItems: 'center',
        minWidth: '200px'
      }}>
        <Link to="/" style={{
          marginRight: '15px',
          color: '#ffffff',
          textDecoration: 'none'
        }}>Home</Link>
        <Link to="/payment" style={{
          marginRight: '15px',
          color: '#ffffff',
          textDecoration: 'none'
        }}>Payment</Link> 

        {user && (
          <>
            <button onClick={handleRedirectToInterfaz} style={{
              marginRight: '15px',
              backgroundColor: '#333',
              color: '#ffffff',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}>
              Account
            </button>
            <button onClick={handleRedirectToPortfolio} style={{
              marginRight: '15px',
              backgroundColor: '#007BFF',
              color: '#ffffff',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}>
              Portfolio
            </button>
            <button onClick={handleRedirectToScreener} style={{
              marginRight: '15px',
              backgroundColor: '#28a745',
              color: '#ffffff',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}>
              Screener
            </button>
          </>
        )}

        {user ? (
          <button onClick={handleLogout} style={{
            marginRight: '15px',
            backgroundColor: '#f44336',
            color: '#ffffff',
            border: 'none',
            padding: '10px 15px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}>
            Log off
          </button>
        ) : (
          <>
            <Link to="/login" style={{
              marginRight: '15px',
              color: '#ffffff',
              textDecoration: 'none'
            }}>Sign in</Link>
            <Link to="/register" style={{
              color: '#ffffff',
              textDecoration: 'none'
            }}>Register</Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
