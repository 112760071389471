import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#121212', 
    },
    formContainer: {
      backgroundColor: '#1e1e1e', 
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
      width: '400px', 
    },
    title: {
      color: '#ffffff', 
      marginBottom: '15px', 
    },
    benefitsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px', 
      marginBottom: '15px',
    },
    benefitCard: {
      backgroundColor: '#292929', 
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      width: '100%', 
      textAlign: 'left',
    },
    benefitTitle: {
      color: '#ffffff', 
      fontWeight: 'bold',
      marginBottom: '5px', 
    },
    benefitDescription: {
      color: '#cccccc', 
    },
    button: {
      padding: '10px 15px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
      width: '100%',
      margin: '10px 0', 
    },
    loadingButton: {
      backgroundColor: '#007bff',
      cursor: 'not-allowed', 
    },
    error: {
      color: '#f44336', 
      margin: '10px 0',
    },
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://eyeopeningstocks.com/backend/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }), 
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Server response error: ${errorDetails}`);
      }

      const { id } = await response.json();

      if (!id) {
        throw new Error('Invalid session ID');
      }

      const stripe = window.Stripe('pk_test_51Q4o9MCDfcrREdokxMekxWx8oybzBrDUw5SabsX53y0Cnu250Vc3kUYnVskigadsEapGCjyPHDdxNwsu6b2rB0kH00zGBk8pOO');
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        alert(error.message);
      }
    } catch (error) {
      alert('Payment processing error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return <div style={{ color: '#ffffff' }}>Please sign in to access the payment page.</div>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Premium Subscription Benefits</h2>
        <div style={styles.benefitsContainer}>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Access to Exclusive Reports</div>
            <div style={styles.benefitDescription}>Get detailed analysis not available to regular users.</div>
          </div>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Real-time Market Updates</div>
            <div style={styles.benefitDescription}>Receive instant alerts on relevant market movements.</div>
          </div>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Personalized Advisory</div>
            <div style={styles.benefitDescription}>Enjoy advice tailored to your financial needs and goals.</div>
          </div>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Future Purchase Discounts</div>
            <div style={styles.benefitDescription}>Access exclusive discounts on additional products and services.</div>
          </div>
        </div>
        <p style={{ color: '#ffffff' }}>Get all these benefits for just <strong>$9.99 per month</strong>!</p>
        <p style={{ color: '#ffffff' }}>Welcome, <strong>{user.email}</strong>!</p>
        <button
          style={loading ? { ...styles.button, ...styles.loadingButton } : styles.button}
          onClick={handlePayment}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Pay Premium Subscription'}
        </button>
      </div>
    </div>
  );
};

export default Payment;
