import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import './Screener.css';

const Screener = () => {
  const [stockTableData, setStockTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueCountries, setUniqueCountries] = useState([]);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    country: '',
    upside: 'both',
    marketCapRange: '',
    epsRange: '',
    bookValueRange: '',
    business: ''
  });

  // Memoizar los rangos para evitar recreaciones innecesarias
  const ranges = useMemo(() => ({
    marketCap: [
      { label: 'All', value: '' },
      { label: 'Micro < 300M', value: '0-300000000' },
      { label: 'Small 300M - 2B', value: '300000000-2000000000' },
      { label: 'Mid 2B - 10B', value: '2000000000-10000000000' },
      { label: 'Large 10B - 100B', value: '10000000000-100000000000' },
      { label: 'Mega > 100B', value: '100000000000-999999999999' }
    ],
    eps: [
      { label: 'All', value: '' },
      { label: 'Negative < 0', value: '-999999-0' },
      { label: '0 - 1', value: '0-1' },
      { label: '1 - 5', value: '1-5' },
      { label: '5 - 20', value: '5-20' },
      { label: '> 20', value: '20-999999' }
    ],
    bookValue: [
      { label: 'All', value: '' },
      { label: '< 5', value: '0-5' },
      { label: '5 - 20', value: '5-20' },
      { label: '20 - 50', value: '20-50' },
      { label: '50 - 100', value: '50-100' },
      { label: '> 100', value: '100-999999' }
    ]
  }), []);

  // Memoizar la función de limpieza de datos
  const cleanAndCombineData = useCallback((data) => {
    const parseNumber = (value) => {
      if (value === '#N/A' || value === undefined || value === '') return null;
      const num = parseFloat(value);
      return isNaN(num) ? null : num;
    };

    return data.map(row => ({
      ticker: row['Ticker'],
      name: row['Name'],
      business: row['Business'] || row['Bussines'] || '',
      targetPrice: parseNumber(row['Valuation']),
      upside: parseNumber(row['Valuation/Current Price - 1']),
      actualPrice: parseNumber(row['Market Price']),
      marketCap: parseNumber(row['Market cap']),
      eps: parseNumber(row['EPS']),
      bookValue: parseNumber(row['Book Value']),
      country: row['Country'] || ''
    }));
  }, []);

  // Memoizar la función de filtrado
  const filterData = useCallback(() => {
    let filtered = [...stockTableData];

    const filterByRange = (value, range) => {
      if (!range) return true;
      const [min, max] = range.split('-').map(Number);
      return value >= min && value <= max;
    };

    if (filters.country) {
      filtered = filtered.filter(stock => stock.country === filters.country);
    }
    if (filters.business) {
      filtered = filtered.filter(stock => stock.business === filters.business);
    }
    if (filters.upside !== 'both') {
      filtered = filtered.filter(stock => 
        filters.upside === 'positive' ? stock.upside > 0 : stock.upside < 0
      );
    }
    if (filters.marketCapRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.marketCap, filters.marketCapRange)
      );
    }
    if (filters.epsRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.eps, filters.epsRange)
      );
    }
    if (filters.bookValueRange) {
      filtered = filtered.filter(stock => 
        filterByRange(stock.bookValue, filters.bookValueRange)
      );
    }

    setFilteredData(filtered);
  }, [filters, stockTableData]);

  // Cargar datos con manejo de caché
  const loadStockData = useCallback(async () => {
    setIsLoading(true);
    try {
      // Intentar obtener datos de caché
      const cachedData = localStorage.getItem('stockData');
      const cachedTimestamp = localStorage.getItem('stockDataTimestamp');
      const CACHE_DURATION = 5 * 60 * 1000; // 5 minutos

      if (cachedData && cachedTimestamp && Date.now() - parseInt(cachedTimestamp) < CACHE_DURATION) {
        const parsedData = JSON.parse(cachedData);
        setStockTableData(parsedData);
        setFilteredData(parsedData);
        processUniqueValues(parsedData);
      } else {
        const response = await axios.get('https://eyeopeningstocks.com/backend/resultados');
        const fileData = response.data;

        if (fileData && fileData.length > 0) {
          const stockData = cleanAndCombineData(fileData);
          
          // Guardar en caché
          localStorage.setItem('stockData', JSON.stringify(stockData));
          localStorage.setItem('stockDataTimestamp', Date.now().toString());

          setStockTableData(stockData);
          setFilteredData(stockData);
          processUniqueValues(stockData);
        }
      }
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [cleanAndCombineData]);

  // Función para procesar valores únicos
  const processUniqueValues = useCallback((data) => {
    const countries = [...new Set(data
      .map(stock => stock.country)
      .filter(country => 
        country && 
        isNaN(country) && 
        !/^\d+$/.test(country)
      ))];
    const types = [...new Set(data.map(stock => stock.business).filter(Boolean))];
    
    setUniqueCountries(countries);
    setUniqueTypes(types);
  }, []);

  useEffect(() => {
    loadStockData();
  }, [loadStockData]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  // Memoizar el renderizado de la tabla
  const TableContent = useMemo(() => (
    <tbody>
      {filteredData.length > 0 ? (
        filteredData.map((stock, index) => (
          <tr key={index}>
            <td>{stock.ticker}</td>
            <td>{stock.name}</td>
            <td>{stock.business}</td>
            <td>{stock.country}</td>
            <td>{stock.upside !== null ? `${(stock.upside * 100).toFixed(2)}%` : '-'}</td>
            <td>{stock.targetPrice !== null ? `$${stock.targetPrice.toFixed(2)}` : '-'}</td>
            <td>{stock.actualPrice !== null ? `$${stock.actualPrice.toFixed(2)}` : '-'}</td>
            <td>{stock.marketCap !== null ? formatMarketCap(stock.marketCap) : '-'}</td>
            <td>{stock.eps !== null ? stock.eps.toFixed(2) : '-'}</td>
            <td>{stock.bookValue !== null ? stock.bookValue.toFixed(2) : '-'}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="10">{isLoading ? 'Loading...' : 'No results found'}</td>
        </tr>
      )}
    </tbody>
  ), [filteredData, isLoading]);

  return (
    <div className="screener-container">
      <h1>Stock Screener</h1>

      <div className="filters-container">
        <div className="filters-grid">
          <div className="filter-group">
            <label>Country</label>
            <select 
              value={filters.country}
              onChange={(e) => setFilters({...filters, country: e.target.value})}
            >
              <option value="">All Countries</option>
              {uniqueCountries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>Sector</label>
            <select 
              value={filters.business}
              onChange={(e) => setFilters({...filters, business: e.target.value})}
            >
              <option value="">All Sectors</option>
              {uniqueTypes.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>Upside</label>
            <select 
              value={filters.upside}
              onChange={(e) => setFilters({...filters, upside: e.target.value})}
            >
              <option value="both">All</option>
              <option value="positive">Positive</option>
              <option value="negative">Negative</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Market Cap</label>
            <select 
              value={filters.marketCapRange}
              onChange={(e) => setFilters({...filters, marketCapRange: e.target.value})}
            >
              {ranges.marketCap.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>EPS</label>
            <select 
              value={filters.epsRange}
              onChange={(e) => setFilters({...filters, epsRange: e.target.value})}
            >
              {ranges.eps.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>

          <div className="filter-group">
            <label>Book Value</label>
            <select 
              value={filters.bookValueRange}
              onChange={(e) => setFilters({...filters, bookValueRange: e.target.value})}
            >
              {ranges.bookValue.map((range, index) => (
                <option key={index} value={range.value}>{range.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="results-counter">
        <p>Showing {filteredData.length} results</p>
      </div>

      <div className="table-container">
        <table className="stock-table">
          <thead>
            <tr>
              <th>Ticker</th>
              <th>Name</th>
              <th>Sector</th>
              <th>Country</th>
              <th>Upside (%)</th>
              <th>Target Price</th>
              <th>Current Price</th>
              <th>Market Cap</th>
              <th>EPS</th>
              <th>Book Value</th>
            </tr>
          </thead>
          {TableContent}
        </table>
      </div>
    </div>
  );
};

// Memoizar la función de formato
const formatMarketCap = (value) => {
  if (!value) return '-';
  if (value >= 1000000000000) {
    return `${(value / 1000000000000).toFixed(2)}T`;
  }
  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(2)}B`;
  }
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(2)}M`;
  }
  return `${value.toFixed(2)}`;
};

export default Screener;
