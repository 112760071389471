import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const Portfolio = () => {
    const { user } = useAuth();
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPortfolio = async () => {
            if (!user) {
                setError('You must be authenticated to view your portfolio.');
                setLoading(false);
                return;
            }

            try {
                const stocksRef = collection(db, 'portfolios', user.uid, 'stocks');
                const stocksSnapshot = await getDocs(stocksRef);

                
                const stocksData = await Promise.all(stocksSnapshot.docs.map(async (doc) => {
                    const stockData = doc.data();
                    let additionalData = {};
                    
                    try {
                        
                        const response = await axios.get(`https://eyeopeningstocks.com/backend/api/stocks/${stockData.ticker}`);
                        const { price, volume, peRatio, dividendRate, eps, bookValue, marketCap } = response.data;
                        
                        additionalData = {
                            currentPrice: price || 'No available',
                            volume: volume || 'No available',
                            peRatio: peRatio || 'No available',
                            dividendRate: dividendRate || 'No available',
                            eps: eps || 'No available',
                            bookValue: bookValue || 'No available',
                            marketCap: marketCap || 'No available',
                        };
                    } catch (err) {
                        console.error(`Error getting data for stock ${stockData.ticker}:`, err);
                        additionalData = { error: 'Error getting data.' };
                    }

                    return {
                        id: doc.id,
                        ...stockData,
                        ...additionalData,
                    };
                }));

                setStocks(stocksData);
            } catch (err) {
                console.error('Error retrieving portfolio:', err);
                setError('Error retrieving portfolio: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPortfolio();
    }, [user]);

    const handleStockClick = (ticker, name) => {
        window.location.href = `/search/${ticker} - ${name}`;
    };

    const handleRemoveStock = async (ticker, e) => {
        e.stopPropagation();
        
        try {
            if (!user) {
                alert('You must be authenticated to remove stocks.');
                return;
            }

            const stockDocRef = doc(db, 'portfolios', user.uid, 'stocks', ticker);
            await deleteDoc(stockDocRef);
            
            setStocks(stocks.filter(stock => stock.ticker !== ticker));
            alert(`${ticker} has been removed from your portfolio.`);
        } catch (err) {
            console.error('Error removing stock:', err);
            alert('Error removing stock from portfolio.');
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="portfolio-container">
            <h1>Your Portfolio</h1>
            {stocks.length === 0 ? (
                <p>You don't have any stocks in your portfolio.</p>
            ) : (
                <table className="portfolio-table">
                    <thead>
                        <tr>
                            <th>Ticker</th>
                            <th>Name</th>
                            <th>Target Price</th>
                            <th>Current Price</th>
                            <th>Volume</th>
                            <th>PE Ratio</th>
                            <th>Dividend Yield</th>
                            <th>EPS</th>
                            <th>Book Value</th>
                            <th>Market Cap</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stocks.map(stock => (
                            <tr 
                                key={stock.id}
                                onClick={() => handleStockClick(stock.ticker, stock.name)}
                                className="portfolio-row"
                                style={{ cursor: 'pointer' }}
                            >
                                <td>{stock.ticker}</td>
                                <td>{stock.name}</td>
                                <td>${stock.targetPrice.toFixed(2)}</td>
                                <td>{stock.currentPrice !== 'No available' ? `$${stock.currentPrice}` : 'No available'}</td>
                                <td>{stock.volume}</td>
                                <td>{stock.peRatio}</td>
                                <td>{stock.dividendRate}</td>
                                <td>{stock.eps}</td>
                                <td>{stock.bookValue}</td>
                                <td>{stock.marketCap}</td>
                                <td>
                                    <button
                                        onClick={(e) => handleRemoveStock(stock.ticker, e)}
                                        className="remove-button"
                                    >
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Portfolio;
