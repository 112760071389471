import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('./UserInterface.js'); 
    } catch (error) {
      setErrorMessage('Error in sign in. Verify your credentials.');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate('./UserInterface.js'); 
    } catch (error) {
      setErrorMessage('Error signing in with Google.');
    }
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#121212', 
    },
    formContainer: {
      backgroundColor: '#1e1e1e', 
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
      width: '300px',
    },
    title: {
      color: '#ffffff', 
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      padding: '10px',
      margin: '10px 0',
      borderRadius: '4px',
      border: '1px solid #444', 
      backgroundColor: '#292929', 
      color: '#ffffff', 
      boxSizing: 'border-box',
    },
    button: {
      padding: '10px 15px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
      width: '100%',
      margin: '10px 0', 
    },
    googleButton: {
      backgroundColor: '#db4437', 
      margin: '10px 0', 
    },
    error: {
      color: '#f44336', 
      margin: '10px 0',
    },
    registerPrompt: {
      color: '#ffffff', 
      marginTop: '10px',
    },
    link: {
      color: '#007bff', 
      textDecoration: 'none', 
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Sign In</h2>
        {errorMessage && <p style={styles.error}>{errorMessage}</p>}
        <form onSubmit={handleLogin} style={styles.form}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={styles.input}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>Sign In</button>
          <button onClick={handleGoogleLogin} style={{ ...styles.button, ...styles.googleButton }}>
            Sign In with Google
          </button>
        </form>
        <p style={styles.registerPrompt}>
          Don't have an account? <Link to="/register" style={styles.link}>Register here</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
