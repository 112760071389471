import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios'; 
import { auth } from './firebaseConfig'; 
import Header from './components/Header'; 
import Login from './components/Login'; 
import Register from './components/Register'; 
import Main from './components/Main'; 
import StockDetail from './components/StockDetail'; 
import Payment from './components/Payment'; 
import Interfaz from './components/Interfaz'; 
import Portfolio from './components/Portfolio'; 
import { AuthProvider, useAuth } from './context/AuthContext'; 
import Screener from './components/Screener';


const App = () => {
  const [data, setData] = useState([]);
  const [portfolio, setPortfolio] = useState([]); 

  const handleSearch = async (query) => {
    const { user } = auth;
    const searchCount = parseInt(localStorage.getItem('searchCount') || '0');

    // Bloquear búsqueda si el usuario no está autenticado y ha alcanzado el límite
    if (!user && searchCount >= 10) {
      return null;
    }

    try {
      // If only the ticker is received, we need to get the full name
      if (!query.includes(' - ')) {
        const ticker = query.trim().toUpperCase();
        
        // Get the list of files to find the full name
        const filesResponse = await fetch('https://eyeopeningstocks.com/backend/files');
        if (!filesResponse.ok) {
          throw new Error('Error getting file list');
        }
        
        const files = await filesResponse.json();
        const matchingFile = files.find(file => 
          file.startsWith(ticker + ' - ') && file.endsWith('.xlsx')
        );

        if (!matchingFile) {
          throw new Error(`File not found for ticker ${ticker}`);
        }

        query = matchingFile.replace('.xlsx', '');
      }

      const fileName = `${query}.xlsx`;

      const response = await fetch(`https://eyeopeningstocks.com/backend/search/${encodeURIComponent(fileName)}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('No data found for this stock');
      }

      const excelData = await response.json();
      const ticker = query.split(' - ')[0].trim();
      
      try {
        const stockResponse = await fetch(`https://eyeopeningstocks.com/backend/api/stocks/${ticker}`);
        
        if (!stockResponse.ok) {
          throw new Error(`Error getting financial data: ${stockResponse.statusText}`);
        }
        
        let stockData = await stockResponse.json();

        if (!stockData || typeof stockData !== 'object') {
          throw new Error('Invalid financial data');
        }

        // Ensure all fields exist, even if they are null
        stockData = {
          price: stockData.price ?? null,
          volume: stockData.volume ?? null,
          peRatio: stockData.peRatio ?? null,
          dividendRate: stockData.dividendRate ?? null,
          eps: stockData.eps ?? null,
          bookValue: stockData.bookValue ?? null,
          marketCap: stockData.marketCap ?? null,
          fiftyTwoWeekHigh: stockData.fiftyTwoWeekHigh ?? null,
          fiftyTwoWeekLow: stockData.fiftyTwoWeekLow ?? null,
          averageVolume: stockData.averageVolume ?? null,
          beta: stockData.beta ?? null,
          trailingPE: stockData.trailingPE ?? null
        };

        const combinedData = [
          ...excelData,
          {
            sheetName: 'Financial Data',
            data: [
              ['Metric', 'Value'],
              ['Current Price', formatFinancialValue(stockData.price)],
              ['Volume', formatFinancialValue(stockData.volume)],
              ['PE Ratio', formatFinancialValue(stockData.peRatio)],
              ['Dividend Rate', formatFinancialValue(stockData.dividendRate)],
              ['EPS', formatFinancialValue(stockData.eps)],
              ['Book Value', formatFinancialValue(stockData.bookValue)],
              ['Market Cap', formatFinancialValue(stockData.marketCap)],
              ['52 Week High', formatFinancialValue(stockData.fiftyTwoWeekHigh)],
              ['52 Week Low', formatFinancialValue(stockData.fiftyTwoWeekLow)],
              ['Average Volume', formatFinancialValue(stockData.averageVolume)],
              ['Beta', formatFinancialValue(stockData.beta)],
              ['Trailing PE', formatFinancialValue(stockData.trailingPE)]
            ]
          }
        ];

        setData(combinedData);
        return combinedData;
      } catch (error) {
        setData(excelData);
        return excelData;
      }
    } catch (error) {
      setData([]);
      return null;
    }
  };

  // Helper function to format financial values
  const formatFinancialValue = (value) => {
    if (value === undefined || value === null) return 'N/A';
    if (typeof value === 'number') {
      if (value > 1000000000) {
        return `${(value / 1000000000).toFixed(2)}B`;
      }
      if (value > 1000000) {
        return `${(value / 1000000).toFixed(2)}M`;
      }
      if (value > 1000) {
        return `${(value / 1000).toFixed(2)}K`;
      }
      return value.toFixed(2);
    }
    return value;
  };

  // Function to add a stock to the portfolio
  const addToPortfolio = (stock) => {
    setPortfolio((prevPortfolio) => [...prevPortfolio, stock]); 
    
    axios.post('https://eyeopeningstocks.com/backend/api/portfolio', stock)
      .catch(error => {});
  };

  return (
    <AuthProvider>
      <Router>
        <Header onSearch={handleSearch} showSearch={true} />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/search/:stockName" 
            element={<StockDetail data={data} onSearch={handleSearch} />} 
          />
          <Route path="/portfolio" element={<Portfolio portfolio={portfolio} />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/interfaz" element={<InterfazWrapper />} />
          <Route path="/screener" element={<Screener addToPortfolio={addToPortfolio} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Wrapper for the Interfaz component that uses the authentication context
const InterfazWrapper = () => {
  const { user } = useAuth(); 

  return (
    <Interfaz
      userId={user ? user.uid : null}
      subscriptionId={user ? user.subscriptionId : null}
    />
  );
};

export default App;
