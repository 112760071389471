// src/components/Main.js
import React from 'react';
import './Main.css'; 

const Main = () => {
  return (
    <main style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Welcome to Open Eyes stock</h1>
        <p style={styles.description}>
        Here you will find valuable information about the markets and financial analysis.
        </p>
      </header>
      <section style={styles.cardContainer}>
        <Card 
          title="Stock Analysis"
          description="Explore detailed analyses of the most relevant stocks in the market."
          link="/stocks"
        />
        <Card 
          title="Market Trends"
          description="Stay on top of the latest trends and movements in the market."
          link="/trends"
        />
        <Card 
          title="Investment Tools"
          description="Access useful tools that will help you in your investment decisions."
          link="/tools"
        />
        <Card 
          title="Dividends"
          description="Learn about stock dividends and how they affect your investments."
          link="/dividends"
        />
      </section>
    </main>
  );
};

const Card = ({ title, description, link }) => {
  return (
    <div style={styles.card}>
      <h2 style={styles.cardTitle}>{title}</h2>
      <p style={styles.cardDescription}>{description}</p>
      <a href={link} style={styles.cardLink}>Leer más</a>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    minHeight: '100vh',
    backgroundImage: 'url("/images/OIP.jfif")', 
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  title: {
    color: '#ffffff',
    fontSize: '32px',
  },
  description: {
    fontSize: '18px',
    color: '#dcdcdc',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: '10px',
    width: '300px',
    textAlign: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    ':hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    },
  },
  cardTitle: {
    fontSize: '24px',
    margin: '10px 0',
    color: '#343a40',
  },
  cardDescription: {
    color: '#6c757d',
    margin: '10px 0',
  },
  cardLink: {
    textDecoration: 'none',
    color: '#007bff',
    fontWeight: 'bold',
  },
  footer: {
    textAlign: 'center',
    marginTop: '40px',
    padding: '10px 0',
    backgroundColor: '#e9ecef',
    borderTop: '1px solid #dee2e6',
  },
};

export default Main;
