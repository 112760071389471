import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';
import { Toaster, toast } from 'react-hot-toast';
import { useAuth } from '../context/AuthContext';
import AuthModal from './AuthModal';

const SearchBar = ({ onSubmit }) => {
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchCount, setSearchCount] = useState(() => {
    return parseInt(localStorage.getItem('searchCount') || '0');
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    localStorage.setItem('searchCount', searchCount.toString());
  }, [searchCount]);

  useEffect(() => {
    if (user) {
      const isNewUser = !localStorage.getItem('hasRegistered');
      if (isNewUser) {
        setSearchCount(0);
        localStorage.setItem('searchCount', '0');
        localStorage.setItem('hasRegistered', 'true');
      }
    }
  }, [user]);

  useEffect(() => {
    if (!user && searchCount === 10) {
      setShowAuthModal(true);
      toast('You have reached your 10 free searches limit', {
        icon: '🔒',
        duration: 4000,
      });
    }
  }, [searchCount, user]);

  const fetchFileNames = async () => {
    try {
      const response = await fetch('https://eyeopeningstocks.com/backend/files');
      const data = await response.json();
      setFileNames(data.map(file => file.replace('.xlsx', ''))); 
    } catch (error) {
      console.error('Error fetching file names:', error);
    }
  };

  useEffect(() => {
    fetchFileNames(); 
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user && searchCount >= 10) {
      setShowAuthModal(true);
      return;
    }

    if (!searchTerm.trim()) {
      toast('Please enter a search term', {
        icon: '⚠️',
      });
      return;
    }

    setIsLoading(true);
    try {
      const fullName = fileNames.find(name => 
        name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
        name.split(' - ')[0].toLowerCase() === searchTerm.toLowerCase()
      );
      
      if (!user && searchCount >= 10) {
        return;
      }

      if (fullName) {
        onSubmit(fullName);
        const encodedName = encodeURIComponent(fullName);
        navigate(`/search/${encodedName}`);
      } else {
        const upperTicker = searchTerm.toUpperCase();
        onSubmit(upperTicker);
        navigate(`/search/${encodeURIComponent(upperTicker)}`);
      }
      
      if (!user) {
        const newCount = searchCount + 1;
        setSearchCount(newCount);
        
        if (newCount >= 7 && newCount < 10) {
          toast(`${10 - newCount} searches remaining`, {
            icon: '⚠️',
            duration: 3000,
          });
        }
      }
      
      setSearchTerm('');
      setFilteredFiles([]);
    } catch (error) {
      console.error('Error searching:', error);
      toast('Error performing search', {
        icon: '❌',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    setFilteredFiles(fileNames.filter(fileName => 
      fileName.toLowerCase().includes(value.toLowerCase()) ||
      fileName.split(' - ')[0].toLowerCase().includes(value.toLowerCase())
    ).slice(0, 5)); 
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && filteredFiles.length > 0) {
      e.preventDefault();
      const fullName = filteredFiles[0].replace('.xlsx', '');
      setSearchTerm(fullName);
      onSubmit(fullName);
      navigate(`/search/${encodeURIComponent(fullName)}`);
      setFilteredFiles([]);
    }
  };

  const handleSuggestionClick = (fileName) => {
    if (!user && searchCount >= 10) {
      setShowAuthModal(true);
      return;
    }

    const fullName = fileName.replace('.xlsx', '');
    setSearchTerm(fullName);
    onSubmit(fullName);
    const encodedName = encodeURIComponent(fullName);
    navigate(`/search/${encodedName}`);
    setFilteredFiles([]);

    if (!user) {
      setSearchCount(prevCount => prevCount + 1);
    }
  };

  return (
    <div className="search-container">
      <Toaster position="top-center" />
      <form onSubmit={handleSubmit} className="search-form">
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={!user && searchCount >= 10 ? "Please register to continue searching" : "Search Stock."}
          className="search-input"
          disabled={!user && searchCount >= 10}
          onClick={() => {
            if (!user && searchCount >= 10) {
              setShowAuthModal(true);
            }
          }}
        />
        <button 
          type="submit" 
          className="search-button"
          disabled={!user && searchCount >= 10}
          onClick={() => {
            if (!user && searchCount >= 10) {
              setShowAuthModal(true);
            }
          }}
        >
          search
        </button>
      </form>
      {filteredFiles.length > 0 && (
        <div className="suggestions-container">
          <ul className="suggestions-list">
            {filteredFiles.map((fileName, index) => (
              <li 
                key={index} 
                onClick={() => handleSuggestionClick(fileName)} 
                className="suggestion-item"
              >
                {fileName.replace('.xlsx', '')}
              </li>
            ))}
          </ul>
        </div>
      )}
      {showAuthModal && (
        <AuthModal onClose={() => setShowAuthModal(false)} />
      )}
    </div>
  );
};

export default SearchBar;
