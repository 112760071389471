import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthModal.css';

const AuthModal = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <div className="modal-overlay">
      <div className="auth-modal">
        <h2>Continue Searching</h2>
        <p>Register or login to continue searching stocks</p>
        <div className="auth-buttons">
          <button 
            className="auth-button register"
            onClick={() => navigate('/register')}
          >
            Register
          </button>
          <button 
            className="auth-button login"
            onClick={() => navigate('/login')}
          >
            Login
          </button>
        </div>
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default AuthModal; 